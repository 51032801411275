<script>
/* eslint-disable max-len */
/* eslint-disable vuejs-accessibility/alt-text */
</script>
<template>
  <div id="vueApp">
    <modal
      name="cutModal"
      resizable
      height="auto"
      :max-height="1400"
      :max-width="600"
      scrollable
      adaptive
    >
      <div class="modalcontainer">
        <div
          v-if="uploadError"
          class="uploadError"
        >
          <h4>{{ $t("title") }}</h4>
          <div v-if="errorType==='thumbnails'">
            <h5>{{ $t("error.server.title") }}</h5>
            <p>{{ $t("error.server.text") }}</p>
          </div>
          <div v-if="errorType==='cuts'">
            <h5>{{ $t("error.saving.title") }}</h5>
            <p>{{ $t("error.saving.text") }}</p>
          </div>
          <div v-if="errorType==='filesTooLarge'">
            <h5>{{ $t("error.filesTooLarge.title") }}</h5>
            <p>{{ $t("error.filesTooLarge.text") }}</p>
          </div>
          <div v-if="errorType==='tooManyPages'">
            <h5>{{ $t("error.tooManyPages.title") }}</h5>
            <p>{{ $t("error.tooManyPages.text") }}</p>
          </div>
        </div>
        <div
          v-if="uploadSuccess"
          class="uploadSuccess"
        >
          <h4>{{ $t("title") }}</h4>
          <h5>{{ $t("success.title") }}</h5>
        </div>
        <div v-else-if="onlySinglePages">
          <h5>{{ $t("onlySinglePages.title") }}</h5>
          <p>{{ $t("onlySinglePages.text") }}</p>
        </div>
        <div v-else>
          <h4>{{ cutterTitle }}</h4>
          <p>{{ $t("description") }}</p>

          <div
            v-for="(file, fileIndex) in files"
            :key="file.id"
            class="fileWrapper"
          >
            <template v-if="file.thumbnails.length > 1">
              <div
                v-if="mode === 'files'"
                class="fileName"
                :class="{ clicked: activeDocuments.includes(fileIndex) }"
                @click="toggleDocument(fileIndex)"
              >
                <svg
                  width="74"
                  height="141"
                  viewBox="0 0 74 141"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M64.4798 70.375L1.95411 7.84344C0.245643 6.13482 0.245773 3.36472 1.9544 1.65626C3.66302 -0.0522038 6.43312 -0.052074 8.14158 1.65655L70.6667 64.1875C70.6675 64.1883 70.6683 64.1891 70.6691 64.1899C71.4813 65.0015 72.1256 65.9652 72.5654 67.0259C73.0056 68.0876 73.2322 69.2256 73.2322 70.375C73.2322 71.5244 73.0056 72.6624 72.5654 73.7241C72.1252 74.7858 71.48 75.7503 70.6667 76.5625L8.14157 139.093C6.43311 140.802 3.66301 140.802 1.95439 139.094C0.245767 137.385 0.245638 134.615 1.9541 132.907L64.4798 70.375Z"
                    fill="black"
                  />
                </svg>
                <div class="fileNameText">
                  <strong>{{ file.originalName }}</strong>
                </div>
              </div>

              <template v-if="activeDocuments.includes(fileIndex) || mode!=='files'">
                <div
                  v-for="(thumbnail, pageIndex) in file.thumbnails"
                  :key="pageIndex"
                >
                  <img
                    v-if="mode === 'files'"
                    class="thumbnail"
                    :src="`/temp/thumbnails/${thumbnail.name}`"
                  >
                  <img
                    v-else
                    class="thumbnail"
                    :src="getImage(thumbnail)"
                  >
                  <div
                    v-if="pageIndex + 1 < file.thumbnails.length"
                    class="cutWrapper"
                    @click="toggleCut(fileIndex, pageIndex + 1)"
                  >
                    <div
                      class="cutLine"
                      :class="{
                        selected: files[fileIndex].cutsAfterPages.includes(
                          pageIndex + 1
                        ),
                      }"
                    >
                      <div
                        class="cutIcon"
                        :class="{
                          selected: files[fileIndex].cutsAfterPages.includes(
                            pageIndex + 1
                          ),
                        }"
                      >
                        <svg
                          width="140"
                          height="141"
                          viewBox="0 0 140 141"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.68845 96.1826C12.6113 91.2598 19.2881 88.4941 26.25 88.4941C33.2119 88.4941 39.8887 91.2598 44.8116 96.1826C49.7344 101.105 52.5 107.782 52.5 114.744C52.5 121.706 49.7344 128.383 44.8116 133.306C39.8887 138.229 33.2119 140.994 26.25 140.994C19.2881 140.994 12.6113 138.229 7.68845 133.306C2.76562 128.383 0 121.706 0 114.744C0 107.782 2.76562 101.105 7.68845 96.1826ZM26.25 97.2441C21.6087 97.2441 17.1575 99.0879 13.8756 102.37C10.5937 105.652 8.75 110.103 8.75 114.744C8.75 119.385 10.5937 123.837 13.8756 127.119C17.1575 130.4 21.6087 132.244 26.25 132.244C30.8913 132.244 35.3425 130.4 38.6244 127.119C41.9063 123.837 43.75 119.385 43.75 114.744C43.75 110.103 41.9063 105.652 38.6244 102.37C35.3425 99.0879 30.8913 97.2441 26.25 97.2441Z"
                            fill="#575757"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.68845 8.68259C12.6113 3.75976 19.2881 0.994141 26.25 0.994141C33.2119 0.994141 39.8887 3.75976 44.8116 8.68259C49.7344 13.6054 52.5 20.2822 52.5 27.2441C52.5 34.2061 49.7344 40.8829 44.8116 45.8057C39.8887 50.7285 33.2119 53.4941 26.25 53.4941C19.2881 53.4941 12.6113 50.7285 7.68845 45.8057C2.76562 40.8829 0 34.2061 0 27.2441C0 20.2822 2.76562 13.6054 7.68845 8.68259ZM26.25 9.74414C21.6087 9.74414 17.1575 11.5879 13.8756 14.8698C10.5937 18.1517 8.75 22.6029 8.75 27.2441C8.75 31.8854 10.5937 36.3366 13.8756 39.6185C17.1575 42.9004 21.6087 44.7441 26.25 44.7441C30.8913 44.7441 35.3425 42.9004 38.6244 39.6185C41.9063 36.3366 43.75 31.8854 43.75 27.2441C43.75 22.6029 41.9063 18.1517 38.6244 14.8698C35.3425 11.5879 30.8913 9.74414 26.25 9.74414Z"
                            fill="#575757"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.38531 42.4736C10.6393 40.4082 13.3302 39.7505 15.3955 41.0044L137.896 115.379C139.961 116.633 140.619 119.324 139.365 121.39C138.111 123.455 135.42 124.113 133.354 122.859L10.8545 48.4838C8.78909 47.2299 8.13133 44.539 9.38531 42.4736Z"
                            fill="#575757"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M139.365 20.5986C140.619 22.664 139.961 25.3549 137.896 26.6088L15.3955 100.984C13.3302 102.238 10.6393 101.58 9.38531 99.5147C8.13133 97.4493 8.78909 94.7584 10.8545 93.5044L133.354 19.1294C135.42 17.8755 138.111 18.5332 139.365 20.5986Z"
                            fill="#575757"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
        <div class="modalFooter">
          <template v-if="uploadError">
            <button
              class="button"
              @click="hideModal"
            >
              {{ $t("ok") }}
            </button>
          </template>
          <template v-else-if="uploadSuccess">
            <button
              class="button"
              @click="reloadpage"
            >
              {{ $t("ok") }}
            </button>
          </template>
          <template v-else-if="onlySinglePages">
            <button
              class="button"
              @click="sendCuts"
            >
              {{ $t("save") }}
            </button>
          </template>
          <template v-else>
            <button
              class="button"
              @click="sendCuts"
            >
              {{ $t("save") }}
            </button>
          </template>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import axios from 'axios'

axios.defaults.headers.post['Response-Type'] = 'json'

export default {
  name: 'App',
  components: {},
  data() {
    return {
      files: [],
      companyId: null,
      selectedFiles: null,
      activeDocuments: [],
      context: 'adminInvoices',
      uploadError: false,
      uploadSuccess: false,
      errorType: null,
      fileId: null,
      onlySinglePages: false,
      alreadyUploaded: false,
      mode: null,
      uploadOnly: false,
      config: {
        user: {
          thumbUrl: '/documents/thumbnails/',
          cutsUrl: '/documents/cuts/',
        },
        adminInvoices: {
          thumbUrl: '/admin/documents/thumbnails/',
          cutsUrl: '/admin/documents/cuts/',
        },
        adminDocuments: {
          thumbUrl: '/admin/documents/',
          cutsUrl: '/admin/documents/cuts/?alreadyUploaded=1',
        },
      },
    }
  },
  computed: {
    cutterTitle() {
      return this.mode === 'files' ? this.$t('title') : this.$t('imagesTitle')
    },
  },
  mounted() {
    const initCutterButton = document.getElementById('multipleInvoice')
    initCutterButton.addEventListener('click', () => {
      this.initCutter()
    })

    const initCutterButton2 = document.getElementById('joinImages')
    initCutterButton2.addEventListener('click', () => {
      this.initCutter()
    })

    const initCutterButton3 = document.getElementById('singleImageUpload')
    initCutterButton3.addEventListener('click', () => {
      this.initCutter()
    })
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale
    },
    resetInvoiceCutter() {
      this.files = []
      this.activeDocuments = []
      this.uploadError = false
      this.errorType = null
      this.onlySinglePages = false
    },
    removeValueFromArray(arr, value) {
      const index = arr.indexOf(value)
      if (index > -1) {
        arr.splice(index, 1)
      }
      return arr
    },
    showModal() {
      this.$modal.show('cutModal')
    },
    reloadpage() {
      if (this.context === 'adminInvoices') {
        // eslint-disable-next-line no-restricted-globals
        location.replace(`/admin/companies/${this.companyId}/#invoices`)
      }
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    },
    hideModal() {
      this.resetInvoiceCutter()
      this.$modal.hide('cutModal')
    },
    toggleDocument(fileIndex) {
      if (this.activeDocuments.includes(fileIndex)) {
        this.activeDocuments = this.removeValueFromArray(this.activeDocuments, fileIndex)
      } else {
        this.activeDocuments.push(fileIndex)
      }
    },
    toggleCut(file, page) {
      if (this.files[file].cutsAfterPages.includes(page)) {
        const index = this.files[file].cutsAfterPages.indexOf(page)
        if (index !== -1) {
          this.files[file].cutsAfterPages.splice(index, 1)
        }
      } else {
        this.files[file].cutsAfterPages.push(page)
        this.files[file].cutsAfterPages.sort((a, b) => a - b)
      }
    },
    sendCuts() {
      if (this.mode === 'files') {
        const loader = this.$loading.show({})
        const filesToSend = this.files
        filesToSend.forEach((file) => {
          // eslint-disable-next-line no-param-reassign
          delete file.thumbnails
        })
        axios
          .post(this.config[this.context].cutsUrl, filesToSend, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(() => {
            this.uploadSuccess = true
            this.showModal()
            loader.hide()
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error === 'Too many pages in one document') {
                this.errorType = 'tooManyPages'
              } else {
                this.errorType = 'cuts'
              }
              this.uploadError = true
              this.showModal()
              loader.hide()
            } else {
              this.uploadError = true
              this.errorType = 'cuts'
              this.showModal()
              loader.hide()
            }
          })
      } else {
        this.joinImages()
      }
    },
    getImage(fileObj) {
      return window.URL.createObjectURL(fileObj)
    },
    getImages() {
      const loader = this.$loading.show({})
      let data
      let headers
      let filesTooLarge = false

      if (this.alreadyUploaded !== true) {
        const formData = new FormData()
        Array.from(this.selectedFiles).forEach((file) => {
          if (file.size > 7000000) {
            filesTooLarge = true
            this.uploadError = true
            this.errorType = 'filesTooLarge'
            this.showModal()
            loader.hide()
          } else {
            formData.append('files[]', file)
          }
        })
        data = formData
        headers = { 'Content-Type': 'multipart/form-data' }
      }
      if (filesTooLarge === false) {
        let url
        if (this.context === 'adminDocuments') {
          url = `${this.config.adminDocuments.thumbUrl}${this.fileId}/thumbnails/`
        } else {
          const path = this.context === 'adminInvoices' ? `${this.companyId}/` : ''
          url = this.config[this.context].thumbUrl + path
        }
        axios
          .post(url, data, {
            headers,
          })
          .then((res) => {
            const activeDocuments = []
            let onlySinglePages = true
            const files = res.data
            files.forEach((file, i) => {
              // eslint-disable-next-line no-underscore-dangle,  no-param-reassign
              file.id = file._id
              // eslint-disable-next-line no-underscore-dangle,  no-param-reassign
              delete file._id
              // eslint-disable-next-line no-param-reassign
              file.companyId = this.companyId
              // eslint-disable-next-line no-param-reassign
              file.cutsAfterPages = []
              activeDocuments.push(i)
              if (file.thumbnails.length > 1) {
                onlySinglePages = false
              }
            })
            this.onlySinglePages = onlySinglePages
            this.files = files
            this.activeDocuments = activeDocuments
            this.showModal()
            loader.hide()
          })
          .catch(() => {
            this.uploadError = true
            this.errorType = 'thumbnails'
            this.showModal()
            loader.hide()
          })
      }
    },
    joinImages() {
      const loader = this.$loading.show({})
      const formData = new FormData()
      let filesTooLarge = false
      Array.from(this.selectedFiles).forEach((file) => {
        if (file.size > 7000000) {
          filesTooLarge = true
          this.uploadError = true
          this.errorType = 'filesTooLarge'
        } else {
          formData.append('files[]', file)
        }
      })
      if (filesTooLarge === false) {
        axios
          .post('/documents/joinimages/upload/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            const data = [{
              id: res.data.imageUploadId,
              joinAfterPages: this.files[0].cutsAfterPages,
              fileNameList: res.data.fileNameList,
              companyId: this.companyId,
            }]
            axios
              .post('/documents/joinimages/', data, {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .then(() => {
                this.uploadSuccess = true
                loader.hide()
                this.showModal()
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.error === 'Too many pages in one document') {
                    this.errorType = 'tooManyPages'
                  } else {
                    this.errorType = 'cuts'
                  }
                  this.uploadError = true
                  this.showModal()
                  loader.hide()
                } else {
                  this.uploadError = true
                  this.errorType = 'cuts'
                  this.showModal()
                  loader.hide()
                }
                this.uploadError = true
                loader.hide()
                this.showModal()
              })
          })
          .catch(() => {
            this.uploadError = true
            this.errorType = 'cuts'
            this.showModal()
            loader.hide()
          })
      }
    },
    initCutter() {
      if (window.cutter) {
        this.companyId = window.cutter.companyId
        this.context = window.cutter.context
        this.mode = window.cutter.mode
        this.uploadOnly = window.cutter.uploadOnly
        const { locale } = window.cutter
        if (locale) {
          if (locale === 'cz') {
            this.setLocale('cs')
          } else {
            this.setLocale(locale)
          }
        } else {
          this.setLocale('cs')
        }

        if (this.mode === 'files') {
          let uploadInput = false
          if (document.getElementById('upload-input')) {
            uploadInput = document.getElementById('upload-input')
          }
          this.selectedFiles = uploadInput.files
          this.getImages()
        } else {
          if (this.mode === 'gallery' && document.getElementById('gallery-input')) {
            this.selectedFiles = document.getElementById('gallery-input').files
          }
          if (this.mode === 'camera' && document.getElementById('camera-input')) {
            this.selectedFiles = document.getElementById('camera-input').files
          }
          const fileArray = []
          const fileObj = {}
          let onlySingleImage = true
          // eslint-disable-next-line no-underscore-dangle
          fileObj.originalName = 'imageJoin'
          fileObj.cutsAfterPages = []
          if (this.selectedFiles.length > 1) {
            onlySingleImage = false
          }
          fileObj.thumbnails = this.selectedFiles
          this.files = fileArray
          if (this.uploadOnly) {
            Array.from(this.selectedFiles).forEach((file, i) => {
              fileObj.cutsAfterPages.push(i + 1)
            })
            fileArray.push(fileObj)
            this.joinImages()
          } else {
            fileArray.push(fileObj)
            if (onlySingleImage) {
              this.joinImages()
            } else {
              this.showModal()
            }
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

// COLORS – Basic
$color_Primary: hsl(190, 100%, 35%);

$color_Light: hsl(0, 0%, 100%);

$color_Primary-Lighter: hsl(190, 100%, 40%);

// WEIGHTS
$weight_Bold: 700;

// SHADOWS
$shadow_Normal: 0 4px 10px rgba(0, 0, 0, 0.2);

// BASIC PADDING AND MARGINS
$spacing: 1.5rem;

h4 {
  margin-top: 0;
  margin-bottom: 10px;
}
.button {
  font-weight: $weight_Bold;
  cursor: pointer;
  transition: all 0.15s ease;
  display: inline-block;
  letter-spacing: 0.025em;
  text-decoration: none;
  line-height: 1.3;
  box-sizing: border-box;
  position: relative;
  border-width: 0;
  border-radius: 3px;
  border-style: solid;
  text-shadow: none;
  text-align: center;
  font-size: 16px;
  padding: $spacing/4 $spacing/2;
  color: $color_Light;
  border-color: $color_Primary;
  background: $color_Primary;
  @media screen and (min-width: 600px) {
    font-size: 18px;
    border-radius: 4px;
    padding: $spacing/3 $spacing;
  }
}

button:hover,
.button:hover {
  background: $color_Primary-Lighter;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.thumbnail {
  max-width: 200px;
  border: 1px solid grey;
  margin: 20px auto;
  display: block;
}

.cutWrapper {
  height: 30px;
  width: 200px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    .cutLine {
      border-top: 1px dotted rgb(58, 58, 58);
    }
    .cutIcon {
      display: flex;
    }
  }
}

.cutLine {
  display: block;
  height: 0;
  width: 100%;
  position: relative;
  margin: 0 auto;
  border-top: 1px dotted #b9b9b9;
  &.selected {
    border-top: 1px dotted rgb(58, 58, 58);
  }
}

.cutIcon {
  display: none;
  width: 35px;
  height: 35px;
  border-radius: 20px;
  position: absolute;
  background: #fff;
  left: 80px;
  top: -18px;
  align-items: center;
  justify-content: center;
  &.selected {
    background: #0095b3;
    display: flex;
    & svg path {
      fill: #fff;
    }
  }
}

.cutIcon svg {
  width: 20px;
  height: 20px;
}

.modalcontainer {
  padding: 30px;
}

.modalFooter {
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.fileName {
  display: flex;
  align-items: center;
  position: relative;
  color: rgb(58, 58, 58);
  .fileNameText {
    display: block;
    position: absolute;
    left: 20px;
    font-size: 14px;
  }
  &:hover {
    cursor: pointer;
    color: rgb(27, 27, 27);
  }
  &.clicked {
    color: rgb(27, 27, 27);
  }
}

.fileName svg {
  width: 10px;
  height: 15px;
}

.clicked.fileName svg {
  transform: rotate(90deg);
}

.fileWrapper {
  margin: 20px 0;
}
.uploadError {
  h5 {
    display: block;
    padding: 8px 12px;
    margin: 0 0 12px;
    background: rgba(255, 0, 43, 0.05);
    color: hsl(0, 86%, 56%);
  }
}

.uploadSuccess {
  h5 {
    display: block;
    padding: 8px 12px;
    margin: 0 0 12px;
    background: rgba(3, 165, 3, 0.05);
    color: hsl(120, 96%, 33%);
  }
}
</style>
